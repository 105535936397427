import { About, Contact, Header, Projects, Testimonials } from 'sections';

import { Head } from 'head';
import { Layout } from 'layout';
import type { NextPage } from 'next';

const Home: NextPage = () => (
  <div>
    <Head />
    <Layout>
      <Header />
      <Projects />
      <About />
      <Testimonials />
      <Contact />
    </Layout>
  </div>
);

export default Home;